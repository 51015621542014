/* Global styling for dark mode */
body {
  font-family: 'Montserrat', sans-serif;
  background-color: #121212;
  color: #ffffff;
  margin: 0;
  padding: 0;
  height: 100vh;
}

/* Bold and centered titles with dark green color */
h1, h2 {
  font-weight: 700;
  color: #2e7d32;
  text-align: center;
}

h2 {
  font-size: 28px;
}

/* Inputs and buttons with larger font size and green color */
input, button {
  font-size: 18px;
  border: none;
  border-radius: 4px;
}

button {
  padding: 14px 28px;
  background-color: #2e7d32;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  display: block;
  margin: 20px auto;
}

button:hover {
  background-color: #276628;
}

button:focus {
  outline: 2px solid #2e7d32;
}
/* General styling for all links */
a {
  color: #2e7d32; /* Green color */
  text-decoration: underline; /* Underline by default */
  transition: color 0.3s ease; /* Smooth color transition */
}

a:hover {
  color: #276628; /* Darker green on hover */
  text-decoration: none; /* Remove underline on hover */
}

/* Specific styling for links in the "Oppdatering" section */
.updates-page a {
  color: #2e7d32; /* Green color for links in updates page */
}

.updates-page a:hover {
  color: #276628; /* Slightly darker green on hover */
  text-decoration: none; /* No underline when hovered */
}


/* Full-screen Background for Login Page */
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('background.webp');
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

/* Overlay effect to tone down the background */
.landing-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 1;
}

/* Positioning form and title above the overlay */
.landing-page h1,
.landing-page form {
  position: relative;
  z-index: 2;
  color: #2e7d32;
  text-align: center;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.landing-page h1 {
  font-size: 120px;
  font-weight: 900;
  margin-bottom: 20px;
}

/* Input styling */
.landing-page input {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

/* Centered and constrained event page container */
.event-page {
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontally center all child elements */
  justify-content: flex-start; /* Content starts at the top */
  padding: 20px;
  max-width: 900px; /* Constrain page width */
  margin: 0 auto; /* Center the event-page horizontally */
  box-sizing: border-box; /* Include padding in width calculations */
}

/* Alternating section background colors */
.event-page section {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.event-page section:nth-child(odd) {
  background-color: #1c1c1c;
}

.event-page section:nth-child(even) {
  background-color: #1a1a1a;
}

/* Paragraph styling within sections */
.event-page p {
  font-size: 18px;
  line-height: 1.6;
  color: #ddd;
}

/* Program List Styling */
.program-list {
  list-style: none;
  padding: 0;
  margin: 20px auto;
  max-width: 600px;
}

.program-list li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px auto;
  padding: 12px;
  background-color: #2b2b2b;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  max-width: 500px;
}

.program-time {
  font-weight: bold;
  color: #2e7d32;
  margin-right: 15px;
  font-size: 18px;
  width: 60px;
  text-align: right;
}

/* Location Subsection Styling */
.location-section {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
}

.location-info {
  flex: 1;
  max-width: 50%;
  text-align: center;
}

.location-info h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #2e7d32;
}

.location-info p {
  font-size: 16px;
  color: #ddd;
  margin: 5px 0;
}

/* Map Styling */
.location-section iframe {
  flex: 1;
  max-width: 50%;
  height: 300px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  /* Landing Page Title and Form */
  .landing-page h1 {
    font-size: 32px;
  }

  .landing-page input, .landing-page button {
    font-size: 14px;
    padding: 8px;
  }

  /* Stacking Subsections on Mobile */
  .location-section {
    flex-direction: column;
    align-items: center;
  }

  .location-info, .location-section iframe {
    max-width: 100%;
  }

  /* Event Page Padding */
  .event-page {
    padding: 20px;
  }

  /* Full-width iframe for RSVP on mobile */
  .iframe-container.open {
    width: 100vw; /* Full width on mobile */
  }

  .iframe-content {
    height: 100%;
    position: relative;
  }

  /* Close button fixed at top left */
  .close-button {
    position: fixed;
    top: 10px;
    left: 10px; /* Move to left side */
    z-index: 1000;
  }
}

/* Image Styling */
.event-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

/* Sliding Iframe Styling */
.iframe-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 0; /* Default hidden width */
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  transition: width 0.4s ease;
  z-index: 999;
  visibility: hidden;
}


.iframe-container.open {
  width: 40vw; /* 30% width for larger screens */
  visibility: visible;
}

/* Responsive design for smaller devices */
@media (max-width: 768px) {
  .iframe-container.open {
    width: 100vw; /* Full width on small screens */
    visibility: visible;
  }
}

/* Iframe overlay */
.iframe-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 998;
  display: none;
}

.iframe-container.open ~ .iframe-overlay {
  display: block;
}

.iframe-content {
  position: relative;
  height: 100%;
}

iframe {
  border: none;
  height: 100%;
  width: 100%;
}


.updates-page {
  padding: 20px;
  text-align: left;
  max-width: 900px;
  margin: 0 auto;
}

.updates-page h2 {
  color: #2e7d32;
  margin-bottom: 20px;
  font-size: 28px;
  text-align: center;
}

.updates-page section {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.updates-page section h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #2e7d32;
}

.updates-page section p {
  font-size: 18px;
  color: #ddd;
  line-height: 1.6;
}


/* Full-screen Background for Login Page */
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('background.webp');
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

/* Overlay effect to tone down the background */
.landing-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 1;
}

/* Positioning form and title above the overlay */
.landing-page h1,
.landing-page form {
  position: relative;
  z-index: 2;
  color: #2e7d32;
  text-align: center;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.landing-page h1 {
  font-size: 120px;
  font-weight: 900;
  margin-bottom: 20px;
}

/* Input styling */
.landing-page input {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.landing-page input:focus {
  outline: 2px solid #2e7d32;
}

/* Button styling */
.landing-page button {
  padding: 14px 28px;
  background-color: #2e7d32;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  display: block;
  margin: 20px auto;
}

.landing-page button:hover {
  background-color: #276628;
}

.landing-page button:focus {
  outline: 2px solid #2e7d32;
}

.update-page {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.update-page h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 16px;
}

.update-page section {
  margin-bottom: 20px;
}

.update-page section h3 {
  color: #555;
  font-size: 20px;
  margin-bottom: 8px;
}

.update-page section p {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}

/* Payment section styling */
.payment-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping */
}

.payment-text {
  flex: 1;
  margin-right: 20px;
}

.payment-qr {
  text-align: center;
}

.payment-qr img {
  max-width: 150px;
  height: auto;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .payment-section {
    flex-direction: column; /* Change to column layout */
  }

  .payment-text {
    margin-right: 0;
    margin-bottom: 20px; /* Add some space between the text and QR code */
  }
}

/* Center the schedule groups inside the event page */
.schedule-group {
  border: 2px solid #2e7d32;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #1a1a1a;
  width: 80%; /* Full width of the parent container */
  max-width: 900px; /* Match the max width of .event-page */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center; /* Center the text within the group */
  margin-left: auto;
  margin-right: auto;
}

/* Group title */
.group-title {
  color: #2e7d32;
  font-size: 22px;
  margin-bottom: 10px; /* Tighten spacing below the title */
}

/* Group description */
.group-description {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 15px; /* Maintain consistent spacing */
  text-align: center;
}

/* Program list to use full group width */
.program-list {
  list-style: none;
  padding: 0;
  margin: 0 auto; /* Center the list */
  width: 100%; /* Full width of the parent container */
  max-width: 800px; /* Constrain width to match the group */
}

/* Individual schedule item */
.schedule-item {
  display: flex;
  justify-content: space-between; /* Align time and title on opposite ends */
  align-items: center;
  font-size: 16px;
  padding: 10px 15px; /* Add padding for spacing */
  border-radius: 6px; /* Rounded corners */
  background-color: #2b2b2b; /* Dark background for items */
  margin-bottom: 10px; /* Space between items */
  width: 100%; /* Full width of the .program-list */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  text-align: left; /* Ensure text is aligned properly */
}

/* Time and title styling */
.program-time {
  color: #2e7d32;
  font-weight: bold;
  flex: 0 0 150px; /* Fixed width for the time */
  text-align: left;
}

.program-title {
  flex: 1; /* Title occupies remaining space */
  text-align: left;
  color: #ddd;
  margin-left: 10px; /* Add spacing between time and title */
}

/* Hover effect for items */
.schedule-item:hover {
  background-color: rgba(46, 125, 50, 0.2); /* Light green hover effect */
}

/* General responsive fix for mobile screens */
@media (max-width: 768px) {
  /* Adjust schedule-group for mobile screens */
  .schedule-group {
    width: 90%; /* Constrain to 90% of the screen width */
    max-width: none; /* Remove any desktop-specific width constraint */
    padding: 15px; /* Adjust padding */
    margin-left: auto;
    margin-right: auto; /* Center the group */
  }

  /* Ensure schedule-item fits within the container */
  .schedule-item {
    display: flex; /* Keep items aligned horizontally */
    flex-direction: row; /* Ensure time and title are on the same row */
    justify-content: space-between; /* Space out time and title */
    align-items: center; /* Align items vertically */
    padding: 10px; /* Reduce padding */
    margin: 10px 0; /* Add spacing between items */
    width: 100%; /* Prevent overflowing the container */
    box-sizing: border-box; /* Include padding in width calculation */
    background-color: #2b2b2b; /* Ensure background matches */
    border-radius: 6px; /* Rounded corners */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  }

  /* Prevent the time from wrapping */
  .program-time {
    color: #2e7d32;
    font-weight: bold;
    flex-shrink: 0; /* Prevent shrinking */
    width: auto; /* Allow time to size based on content */
    margin-right: 15px; /* Spacing between time and title */
    text-align: left; /* Align text to the left */
    font-size: 14px; /* Adjust font size for smaller screens */
    white-space: nowrap; /* Prevent wrapping to the next line */
  }

  /* Ensure the title fits */
  .program-title {
    flex-grow: 1; /* Allow the title to take up remaining space */
    text-align: left; /* Align the title text to the left */
    color: #ddd;
    font-size: 14px; /* Adjust font size for smaller screens */
    margin-left: 0; /* No extra margin on mobile */
  }
}

/* Styling for lists in sections */
section ul {
  list-style: none; /* Fjern standard punktmerking */
  padding: 0; /* Fjern standard padding */
  margin: 0; /* Fjern standard margin */
}

section ul li {
  font-size: 18px; /* Samme størrelse som avsnitt */
  line-height: 1.6; /* Samme linjehøyde for god lesbarhet */
  margin-bottom: 10px; /* Gi litt mellomrom mellom hvert punkt */
  color: #ddd; /* Lik farge som avsnitt */
}

section ul li strong {
  color: #2e7d32; /* Gjør overskrifter i listen mørkegrønne for tydelighet */
}

/* Seksjon for regler */
.rules-section {
  background-color: #1a1a1a; /* Mørk bakgrunn */
  border: 2px solid #2e7d32; /* Grønn ramme */
  border-radius: 8px; /* Avrundede hjørner */
  padding: 20px; /* Innvendig avstand */
  margin: 20px auto; /* Sentraliser seksjonen */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Myk skygge */
  max-width: 80%; /* Begrens bredde */
}

/* Overskrift styling */
.rules-section h2 {
  font-size: 28px; /* Gjør overskriften større */
  color: #2e7d32; /* Fremhev overskriften med grønn farge */
  text-align: center; /* Sentrer teksten */
  text-transform: uppercase; /* Store bokstaver */
  border-bottom: 2px solid #2e7d32; /* Understrekning */
  padding-bottom: 10px; /* Avstand under teksten */
  margin-bottom: 20px; /* Avstand til listen */
}

/* Listeelementer styling */
.rules-section ul {
  list-style: none; /* Fjern standard punktmarkering */
  padding: 0; /* Fjern standard padding */
  margin: 0; /* Fjern standard margin */
}

.rules-section ul li {
  display: flex; /* For ikoner og tekst på samme linje */
  align-items: center; /* Juster vertikalt */
  font-size: 18px; /* Lesbar fontstørrelse */
  line-height: 1.8; /* God linjehøyde */
  color: #ddd; /* Lys tekstfarge */
  margin-bottom: 15px; /* Avstand mellom punkter */
  padding: 10px; /* Innvendig avstand */
  background-color: #2b2b2b; /* Lysere bakgrunn for hvert punkt */
  border-radius: 6px; /* Avrundede hjørner */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtil skygge */
  transition: transform 0.2s ease, background-color 0.2s ease; /* Interaktiv effekt */
}

/* Interaktiv hover-effekt for listepunkter */
.rules-section ul li:hover {
  transform: translateY(-3px); /* Løft punktet litt opp */
  background-color: #3b3b3b; /* Endre bakgrunnsfarge */
}

/* Ikoner foran punktene */
.rules-section ul li::before {
  content: '🎾'; /* Tennisball-ikon */
  margin-right: 15px; /* Avstand til teksten */
  font-size: 18px; /* Samme størrelse som teksten */
}

/* Mobiltilpasning */
@media (max-width: 768px) {
  /* Regler-seksjonen */
  .rules-section {
    width: 90%; /* Ensure it fits within mobile screens */
    padding: 15px; /* Mindre padding for mobil */
  }

  .rules-section h2 {
    font-size: 24px; /* Reduser fontstørrelsen */
    padding-bottom: 8px; /* Mindre avstand under overskrift */
  }

  .rules-section ul li {
    font-size: 16px; /* Reduser fontstørrelsen for punkter */
    line-height: 1.6; /* Strammere linjehøyde */
    padding: 8px; /* Mindre innvendig padding */
    margin-bottom: 10px; /* Mindre mellomrom mellom punkter */
    flex-direction: column; /* Stable ikon og tekst vertikalt */
    text-align: center; /* Sentrer teksten */
  }

  .rules-section ul li::before {
    font-size: 20px; /* Forstørr ikonet litt for bedre synlighet */
    margin-right: 0; /* Fjern margin på ikonet */
    margin-bottom: 5px; /* Legg til avstand mellom ikon og tekst */
  }

  .rules-section ul li:hover {
    transform: none; /* Fjern hover-effekt på mobil */
  }
}

/* General page styling */
.grupper-page {
  font-family: 'Montserrat', sans-serif;
  background-color: #121212;
  color: #ffffff;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
}

.grupper-page h1 {
  color: #2e7d32;
  font-size: 36px;
  margin-bottom: 20px;
}

.grupper-page p {
  font-size: 18px;
  color: #ddd;
  margin-bottom: 30px;
  line-height: 1.6;
}

/* Group styling */
.group {
  background-color: #1a1a1a;
  border: 2px solid #2e7d32;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.group h2 {
  font-size: 28px;
  color: #2e7d32;
  margin-bottom: 15px;
}

/* Section styling */
.section {
  background-color: #2b2b2b;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
}

.section h3 {
  font-size: 22px;
  color: #2e7d32;
  margin-bottom: 10px;
}

.section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.section ul li {
  font-size: 18px;
  color: #ddd;
  background-color: #3b3b3b;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.section ul li:hover {
  background-color: #2e7d32;
  color: #ffffff;
  transition: background-color 0.3s ease-in-out;
}

/* File: src/App.css */
.map-page {
  text-align: center;
  padding: 20px;
}

.zoomable-image {
  max-width: 90%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  margin: 20px 0;
}