/* Navigation Menu */
.nav-menu {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: space-between;
    background-color: #1a1a1a;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Navigation Links */
  .nav-links {
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 20px;
    margin: 0;
    padding: 0;
    align-items: center; /* Align links and button vertically */
  }
  
  /* Link Styling */
  .nav-links a {
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .nav-links a:hover {
    background-color: #2e7d32;
  }
  
  /* Logout Button (specific styling) */
  .nav-links .logout-button {
    background-color: #2e7d32;
    border: none;
    color: white;
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .nav-links .logout-button:hover {
    background-color: #276628;
  }
  
  /* Hamburger Menu Icon */
  .menu-icon {
    display: none; /* Hidden on larger screens */
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
  }
  
  /* Small Screen Menu */
  @media (max-width: 768px) {
    .menu-icon {
      display: block; /* Show hamburger icon */
    }
  
    .nav-links {
      display: none; /* Hide menu by default on small screens */
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 20px;
      background-color: #1a1a1a;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
      z-index: 1000;
    }
  
    .nav-links.open {
      display: flex; /* Show when toggled */
    }
  
    .nav-links a,
    .nav-links .logout-button {
      width: 100%; /* Full width for menu options */
      text-align: left;
      font-size: 18px;
      padding: 10px 15px;
    }
  
    /* Title for Menu */
    .nav-links .menu-title {
      font-size: 20px;
      color: #ffffff;
      font-weight: bold;
      margin-bottom: 10px;
      text-align: center;
      border-bottom: 1px solid #2e7d32;
      padding-bottom: 10px;
    }
  }
  